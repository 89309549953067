import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useClickAway from "react-use/lib/useClickAway";
import { intlFormatDistance } from "date-fns";

import {
  Tag,
  FloraTypography as Text,
  FloraButton as Button,
} from "@grupoboticario/flora-react";
import {
  BellIcon,
  CheckDoubleIcon,
  CrossIcon,
} from "@grupoboticario/flora-react-icons";

import { useReactQuerySubscription } from "./hooks/useReactQuerySubscription";
import { useNotificationList } from "./hooks/useNotificationList";
import { useNotificationsCount } from "./hooks/useNotificationsCount";
import { useReadNotificationItem } from "./hooks/useReadNotificationItem";
import { useReadAllNotifications } from "./hooks/useReadAllNotifications";
import { useInvalidateNotificationQuery } from "./hooks/useInvalidateNotificationQuery";

import { NotificationItem, NotificationIcon } from "./NotificationMenu.styles";
import { NotificationCard } from "./components/NotificationCard";
import { NotificationCardHeader } from "./components/NotificationCardHeader";
import { NotificationList } from "./components/NotificationList";
import { NotificationCardFooter } from "./components/NotificationCardFooter";
import { useSetDocumentTitle } from "./hooks/useSetTitle";

export function NotificationMenuWrapper() {
  useReactQuerySubscription();

  const ref = useRef(null);
  const [isNotificationCardOpen, setIsNotificationCardOpen] = useState(false);
  const navigate = useNavigate();

  const { data: dataNotificationList } = useNotificationList({
    enabled: isNotificationCardOpen,
  });
  const { data: dataNotificationsCount } = useNotificationsCount();
  const { mutate: markOneAsRead } = useReadNotificationItem();
  const { mutate: markAllAsRead } = useReadAllNotifications();
  const invalidateNotificationQuery = useInvalidateNotificationQuery();

  const hasNewNotification = dataNotificationsCount?.count > 0;
  const hasNotificationItems = dataNotificationList?.data.length > 0;
  const dateNow = new Date();

  useSetDocumentTitle(
    `${hasNewNotification ? `(${dataNotificationsCount?.count})` : ""}`,
  );

  useClickAway(ref, () => {
    if (isNotificationCardOpen) {
      setIsNotificationCardOpen(false);
    }
  });

  const toggleNotification = useCallback(() => {
    setIsNotificationCardOpen(!isNotificationCardOpen);
  }, [isNotificationCardOpen]);

  return (
    <div ref={ref}>
      <NotificationIcon
        hasNotification={dataNotificationsCount?.count > 0}
        aria-label="Notificação"
        onClick={toggleNotification}
        icon={<BellIcon />}
        has="iconOnly"
        hierarchy="tertiary"
      />
      {isNotificationCardOpen && dataNotificationList && (
        <NotificationCard>
          <NotificationCardHeader>
            <Text
              fontSize={"bodyLargeShort"}
              fontWeight="medium"
              as="h3"
              css={{ margin: 0 }}
            >
              Notificações{" "}
              {hasNewNotification && (
                <Tag variant="error">{dataNotificationsCount?.count}</Tag>
              )}
            </Text>
            <Button
              hierarchy="tertiary"
              has="iconOnly"
              icon={<CrossIcon />}
              aria-label="Fechar notificações"
              onClick={toggleNotification}
            />
          </NotificationCardHeader>
          <NotificationList>
            {hasNotificationItems ? (
              dataNotificationList.data.map((notification) => (
                <NotificationItem
                  hasNotification={notification.status === "UNREAD"}
                  key={notification.id}
                  onClick={() => {
                    navigate(notification.meta.link);

                    if (notification.status === "READ") return;

                    markOneAsRead(
                      { id: notification.id },
                      {
                        onSuccess: () => {
                          invalidateNotificationQuery();
                        },
                      },
                    );
                  }}
                >
                  {notification.meta.text}{" "}
                  {intlFormatDistance(notification.createdAt, dateNow, {
                    locale: "pt-BR",
                    style: "long",
                  })}
                </NotificationItem>
              ))
            ) : (
              <div style={{ padding: "64px 16px", textAlign: "center" }}>
                Sem notificações
              </div>
            )}
          </NotificationList>
          {hasNotificationItems && hasNewNotification && (
            <NotificationCardFooter>
              <Button
                disabled={!hasNewNotification}
                hierarchy="tertiary"
                size="small"
                has="iconRight"
                icon={<CheckDoubleIcon />}
                css={{ color: "#626CC3", boxShadow: "none !important" }}
                onClick={() => {
                  markAllAsRead(undefined, {
                    onSuccess: () => {
                      invalidateNotificationQuery();
                    },
                  });
                }}
              >
                Marcar todos como lidos
              </Button>
            </NotificationCardFooter>
          )}
        </NotificationCard>
      )}
    </div>
  );
}
